import React from 'react';
import {useTranslation} from "react-i18next";
import style from './TabsBody.module.sass';

const TabUrOsobam = () => {
    const {t, i18n} = useTranslation();
    return (
        <>
            <ol className={style.list}>
                <li className={style.item}>{t('urOsobam.list1.item1')}</li>
                <li className={style.item}>{t('urOsobam.list1.item2')}</li>
                <li className={style.item}>{t('urOsobam.list1.item3')}</li>
                <li className={style.item}>{t('urOsobam.list1.item4')}</li>
                <li className={style.item}>{t('urOsobam.list1.item5')}</li>
                <li className={style.item}>{t('urOsobam.list1.item6')}</li>
                <li className={style.item}>{t('urOsobam.list1.item7')}</li>
                <li className={style.item}>{t('urOsobam.list1.item8')}</li>
                <li className={style.item}>{t('urOsobam.list1.item9')}</li>
                <li className={style.item}>{t('urOsobam.list1.item10')}</li>
                <li className={style.item}>{t('urOsobam.list1.item11')}</li>
                <ul>
                    <li>{t('urOsobam.list1.item12.1')}</li>
                    <li>{t('urOsobam.list1.item12.2')}</li>
                    <li>{t('urOsobam.list1.item12.3')}</li>
                    <li>{t('urOsobam.list1.item12.4')}</li>
                    <li>{t('urOsobam.list1.item12.5')}</li>
                </ul>
            </ol>
        </>
    );
};

export default TabUrOsobam;