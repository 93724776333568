import React from 'react';
import {useTranslation} from "react-i18next";
import Banner from '../components/common/Banner/Banner';
import bgImage from '../assets/img/bg5.jpg'
import DocumentsBody from "../components/Documents/DocumentsBody/DocumentsBody";

const Documents = () => {
    const {t, i18n} = useTranslation();

    return (
        <section className="wrapper">
            <Banner header={t('documents.header')} bgImage={bgImage}/>
            <div className='container'>
                <DocumentsBody/>
            </div>
        </section>
    );
};

export default Documents;