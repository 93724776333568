import React from 'react';
import MainImg from "../../assets/img/bg3.jpg";
import ApplyBtn from "../../UI/ApplyBtn";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Banner = () => {
    const {t, i18n} = useTranslation();

    return (
        <div className="home_banner mb-30">
            <div className="home_banner--wrapper">
                <img className="home_banner--img" src={MainImg} alt="img: Main image"/>
            </div>
            <div className="container home_banner--info">
                <div>
                    <hr className="home_banner--line"/>
                    <h1 className="h1">{t('homeHeader')}</h1>
                    <hr className="home_banner--line"/>
                    <hr className="home_banner--line"/>
                </div>

                <div className="home_banner--menu">
                    <ul className="home_menu">
                        <li className="home_menu--item">
                            <a className="home_menu--link" href="#service">{t('homeNav.services')}</a>
                        </li>
                        <li className="home_menu--item">
                            <a className="home_menu--link" href="#howTake">{t('homeNav.howTake')}</a>
                        </li>
                        <li className="home_menu--item">
                            <a className="home_menu--link" href="#whyWe">{t('homeNav.whyWe')}</a>
                        </li>
                        <li className="home_menu--item">
                            <a className="home_menu--link" href="#advantage">{t('homeNav.advantage')}</a>
                        </li>
                        <li className="home_menu--item">
                            <a className="home_menu--link" href="#contacts">{t('homeNav.contacts')}</a>
                        </li>
                    </ul>
                    <Link className="btn-apply" to="services">{t('allServices')}</Link>
                </div>
            </div>
        </div>
    );
};

export default Banner;