// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC678b5eWZrhm9UrAl_iWsrL_-F6481O9Y",
    authDomain: "lema-pl.firebaseapp.com",
    projectId: "lema-pl",
    storageBucket: "lema-pl.appspot.com",
    messagingSenderId: "268907548378",
    appId: "1:268907548378:web:383720912237dc4feb9fe3",
    measurementId: "G-2WDGM2YPGW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export const db = getFirestore(app);

export {auth};