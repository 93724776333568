import React from "react";
import { useTranslation } from "react-i18next";
import DocIcon from "../../assets/img/icons/documents1.svg";
import Doc2Icon from "../../assets/img/icons/documents2.svg";
import Doc3Icon from "../../assets/img/icons/documents3.svg";

const CompanyInfo = () => {
    const { t, i18n } = useTranslation();

    return (
        <ul className="contacts_info">
            <li className="contacts_info--item">
                <div className="contacts_info--iconWrapper">
                    <img
                        className="contacts_info--icon"
                        src={DocIcon}
                        alt="icon: Location"
                    />
                </div>
            </li>
            <li className="contacts_info--item">
                <div className="contacts_info--iconWrapper">
                    <img
                        className="contacts_info--icon"
                        src={Doc3Icon}
                        alt="icon: Location"
                    />
                </div>
            </li>
            <li className="contacts_info--item">
                <div className="contacts_info--iconWrapper">
                    <img
                        className="contacts_info--icon"
                        src={Doc2Icon}
                        alt="icon: Location"
                    />
                </div>
            </li>
        </ul>
    );
};

export default CompanyInfo;
