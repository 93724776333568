import React, {useContext} from 'react';
import style from "./ServicePage.module.sass";
import Sidebar from "../../components/common/Sidebar/Sidebar";
import Promo2 from "../../components/Commercial/Promo2";
import ApplyBtn from "../../UI/ApplyBtn";
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../App";

const ExpertnayaOtsinka = () => {
    const {t} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    return (
        <div className="wrapper">
            <div className="container">
                <div className={style.container}>
                    <div className={style.wrapper}>
                        <Sidebar/>
                        <div className={style.content}>
                            <h1 className="h2">{t('expert.header')}</h1>
                            <p className={`${style.text} ${style.mb25}`}>
                                {t('expert.text1')}
                            </p>
                            <p className={`${style.text} ${style.mb25}`}>
                                {t('expert.text2')}
                            </p>
                            <p className={`${style.text} ${style.mb25}`}>
                                <strong className={style.text2}>{t('expert.title1')}</strong>
                                {t('expert.text3')}
                            </p>
                            <h6 className={style.title}>
                                {t('expert.title2')}
                            </h6>
                            <ul className={style.list}>
                                <li>{t('expert.list.1')}</li>
                                <li>{t('expert.list.2')}</li>
                                <li>{t('expert.list.3')}</li>
                                <li>{t('expert.list.4')}</li>
                                <li>{t('expert.list.5')}</li>
                                <li>{t('expert.list.6')}</li>
                                <li>{t('expert.list.7')}</li>
                            </ul>
                        </div>
                    </div>
                    <ul className={style.cards}>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('expert.price.point1.name')}</h6>
                            <p className={style.text}>{t('expert.price.point1.day')}</p>
                            <strong className={style.textSm}>{t('expert.price.point1.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('expert.price.point2.name')}</h6>
                            <p className={style.text}>{t('expert.price.point2.day')}</p>
                            <strong className={style.textSm}>{t('expert.price.point2.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('expert.price.point3.name')}</h6>
                            <p className={style.text}>{t('expert.price.point3.day')}</p>
                            <strong className={style.textSm}>{t('expert.price.point3.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('expert.price.point4.name')}</h6>
                            <p className={style.text}>{t('expert.price.point4.day')}</p>
                            <strong className={style.textSm}>{t('expert.price.point4.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('expert.price.point5.name')}</h6>
                            <p className={style.text}>{t('expert.price.point5.day')}</p>
                            <strong className={style.textSm}>{t('expert.price.point5.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                    </ul>
                </div>
                <Promo2/>
            </div>
        </div>
    );
};

export default ExpertnayaOtsinka;