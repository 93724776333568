import React, {useContext} from 'react';
import style from "./ServicePage.module.sass";
import Sidebar from "../../components/common/Sidebar/Sidebar";
import Promo2 from "../../components/Commercial/Promo2";
import ApplyBtn from "../../UI/ApplyBtn";
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../App";

const VvodExpl = () => {
    const {t} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    return (
        <div className="wrapper">
            <div className="container">
                <div className={style.container}>
                    <div className={style.wrapper}>
                        <Sidebar/>
                        <div className={style.content}>
                            <h1 className="h2">{t('expluat.header')}</h1>
                            <p className={`${style.title} ${style.mt0}`}>
                                {t('expluat.title1')}
                            </p>
                            <ul className={`${style.list} ${style.mb25}`}>
                                <li>{t('expluat.list.1')}</li>
                                <li>{t('expluat.list.2')}</li>
                                <li>{t('expluat.list.3')}</li>
                                <li>{t('expluat.list.4')}</li>
                                <li>{t('expluat.list.5')}</li>
                            </ul>
                            <p className={`${style.text3} ${style.mb25}`}>
                                {t('expluat.title2')}
                            </p>
                        </div>
                    </div>
                    <ul className={`${style.cards} ${style.g40}`}>
                        <li className={style.card}>
                            <h6 className={style.titleCardFull}>{t('expluat.price.point1.name')}</h6>
                            <strong className={style.textSm}>{t('expluat.price.point1.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCardFull}>{t('expluat.price.point2.name')}</h6>
                            <strong className={style.textSm}>{t('expluat.price.point2.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCardFull}>{t('expluat.price.point3.name')}</h6>
                            <strong className={style.textSm}>{t('expluat.price.point3.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCardFull}>{t('expluat.price.point4.name')}</h6>
                            <strong className={style.textSm}>{t('expluat.price.point4.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCardFull}>{t('expluat.price.point5.name')}</h6>
                            <strong className={style.textSm}>{t('expluat.price.point5.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCardFull}>{t('expluat.price.point6.name')}</h6>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                    </ul>
                    <p className={style.title}>
                        {t('expluat.title3')}
                    </p>
                </div>
                <Promo2/>
            </div>
        </div>
    );
};

export default VvodExpl;