import React from 'react';
import style from "./Documents.module.sass";
import Tabs from "../Tabs/Tabs";
import Sidebar from "../../common/Sidebar/Sidebar";
import Networks from "../../Contacts/networks";
import Map from "../../Contacts/Map";
import Promo2 from "../../Commercial/Promo2";
import Location from "../../common/Location/Location";

const DocumentsBody = () => {
    return (
        <section>
            <div className={style.content}>
                <Sidebar/>
                <Tabs/>
            </div>
            <div>
                <Promo2/>
                <Networks/>
                <Location/>
            </div>
        </section>
    );
};

export default DocumentsBody;