import React, {useState} from 'react';
import CloseImg from '../assets/img/icons/close.svg';
import Form from "../components/Modal/Form";
import SuccessForm from "../components/Modal/SuccessForm";

const Modal = ({isOpen, setIsOpen}) => {
    const [success, setSuccess] = useState(false);

    const addClosedClass = (element, parentsClass) => {
        const block = element.closest(parentsClass);
        block.classList.add('close');
    }

    return (
        <>
            {
                isOpen &&
                <div className="modal" onClick={(e) => {
                    addClosedClass(e.target, ".modal_wrapper");
                    setTimeout(() => {
                        setIsOpen(false);
                        setSuccess(false);
                    }, 100)
                }}>
                    <div className="modal_wrapper" onClick={(e) => e.stopPropagation()}>
                        <button className="modal_btn--close" onClick={(e) => {
                            addClosedClass(e.target, ".modal_wrapper");
                            setTimeout(() => {
                                setIsOpen(false);
                                setSuccess(false);
                            }, 100)
                        }}>
                            <img className="modal_btn--close-img" src={CloseImg} alt="icon: Close"/>
                        </button>
                        <div className="modal_content">
                            {success ?
                                <SuccessForm/>
                                :
                                <>
                                    <h2 className="h3 modal_header">Заявка</h2>
                                    <Form setSuccess={setSuccess}/>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Modal;