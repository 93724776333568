import style from "../Management.module.sass";
import {updateOrder} from "../../../../api";
import React, {useEffect, useState} from "react";
import {t} from "i18next";

const CheckBox = ({id, checked}) => {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect( () => {
        (async  () => {
            await updateOrder({id: id, checked: isChecked});
        })()

    }, [isChecked])
    return (
        <label htmlFor={id} className={style.checkbox} onClick={(e)=>{e.stopPropagation()}}>
            <input id={id} className={style.input} type="checkbox" checked={isChecked} onChange={(e) => {
                e.stopPropagation();
                setIsChecked(!isChecked);
            }}/>
            {t('admin.check')}
        </label>
    );
};

export default CheckBox;