import React from 'react';
import { useTranslation } from "react-i18next";
import ImgDone from '../../assets/img/icons/done.svg';

const SuccessForm = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className="form_success">
            <h2 className="h3 modal_header">{t('form.success')}</h2>
            <p className="p2 form_success--text">{t('form.successText')}</p>
            <img className="form_success--img" src={ImgDone} alt="img: Done"/>
        </div>
    );
};

export default SuccessForm;