import React from 'react';
import {useTranslation} from "react-i18next";
import style from './TabsBody.module.sass';

const TabFlat = () => {
    const {t, i18n} = useTranslation();
    return (
        <>
            <h4 className={style.header}>{t('tabFlat.header1')}</h4>
            <ol className={style.list}>
                <li className={style.item}>{t('tabFlat.list1.item1')}</li>
                <li className={style.item}>{t('tabFlat.list1.item2')}</li>
                <li className={style.item}>{t('tabFlat.list1.item3')}</li>
            </ol>
            <h4 className={style.header}>{t('tabFlat.header2')}</h4>
            <ol className={style.list}>
                <li className={style.item}>{t('tabFlat.list2.item1')}</li>
                <li className={style.item}>{t('tabFlat.list2.item2')}</li>
                <li className={style.item}>{t('tabFlat.list2.item3')}</li>
            </ol>
            <h4 className={style.header}>{t('tabFlat.header3')}</h4>
            <ol className={style.list}>
                <li className={style.item}>{t('tabFlat.list3.item1')}</li>
                <li className={style.item}>{t('tabFlat.list3.item2')}</li>
                <li className={style.item}>{t('tabFlat.list3.item3')}</li>
                <li className={style.item}>{t('tabFlat.list3.item4')}</li>
            </ol>
            <h4 className={style.header}>{t('tabFlat.header4')}</h4>
            <ol className={style.list}>
                <li className={style.item}>{t('tabFlat.list4.item1')}</li>
                <li className={style.item}>{t('tabFlat.list4.item2')}</li>
                <li className={style.item}>{t('tabFlat.list4.item3')}</li>
                <li className={style.item}>{t('tabFlat.list4.item4')}</li>
                <li className={style.item}>{t('tabFlat.list4.item5')}</li>
                <li className={style.item}>{t('tabFlat.list4.item6')}</li>
            </ol>
        </>
    );
};

export default TabFlat;