import React from 'react';
import {useTranslation} from "react-i18next";

const Map = () => {
    const {t} = useTranslation();
    return (
        <div className="mb-90">
            <h2 className="h5 contacts-map--header">{t('contacts.location3')}</h2>
            <iframe
                className="contacts-map--frame"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7027.472593357877!2d36.21569400390616!3d49.99193153031554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4127a0f90d07213d%3A0x54e6dfcae400438a!2sKatsars&#39;ka%20St%2C%2011%2C%20Kharkiv%2C%20Kharkivs&#39;ka%20oblast%2C%2061000!5e0!3m2!1sen!2sua!4v1673610263984!5m2!1sen!2sua"
                allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            <a className="p1 contacts-map--text" href="https://goo.gl/maps/bNk4WoLKznFHw9297">
                {t("contacts.location")}
            </a>
            <a className="p1 contacts-map--text" href="https://goo.gl/maps/bNk4WoLKznFHw9297">
                {t("contacts.location2")}
            </a>
        </div>
    );
};

export default Map;