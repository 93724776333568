import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../App";
import style from "./Links.module.sass";
import img1 from '../../assets/img/bg1.jpg';
import img2 from '../../assets/img/bg4.jpg';
import img3 from '../../assets/img/bg8.jpg';
import img4 from '../../assets/img/bg9.jpg';
import img5 from '../../assets/img/bg.jpg';

const Links = () => {
    const {t} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    const data = [
        {
            id: 247,
            img: img1,
            name: t('linksPage.1'),
            link: "https://zakon.rada.gov.ua/laws/show/z0582-01#Text"
        },
        {
            id: 243,
            img: img2,
            name: t('linksPage.2'),
            link: "https://zakon.rada.gov.ua/laws/show/z0976-18#Text"
        },
        {
            id: 248,
            img: img3,
            name: t('linksPage.3'),
            link: "https://zakon.rada.gov.ua/laws/show/z0774-07#Text"
        },
        {
            id: 269,
            img: img4,
            name: t('linksPage.4'),
            link: "https://e-construction.gov.ua/reestri"
        },
        {
            id: 202,
            img: img5,
            name: t('linksPage.5'),
            link: "https://kadastr.live/#5.64/48.727/32.92"
        },
    ]

    return (
        <div className="wrapper">
            <div className="container">
                <h1 className="h2">{t('services.list.links')}</h1>
                <ul className={style.list}>
                    {
                        data.map(item=>(
                            <li className={style.card} key={item.id}>
                                <a className={style.link} href={item.link} target="_blank">
                                    <img className={style.img} src={item.img} alt={`img: ${item.name}`}/>
                                    <h5 className={style.name}>{item.name}</h5>
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
};

export default Links;