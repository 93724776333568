import React, {useContext} from 'react'
import style from './Favor.module.sass';
import {useTranslation} from "react-i18next";
import Step1 from '../../../assets/img/icons/step1.svg';
import Step2 from '../../../assets/img/icons/step2.svg';
import Step3 from '../../../assets/img/icons/step3.svg';
import Step4 from '../../../assets/img/icons/step4.svg';
import ApplyBtn from "../../../UI/ApplyBtn";
import {GlobalContext} from "../../../App";

const Favor = () => {
    const {t, i18n} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    const data = [
        {
            id: "10",
            icon: Step1,
            alt: 'icon: step 1',
            text: t('favor.step1')
        }, {
            id: "11",
            icon: Step2,
            alt: 'icon: step 2',
            text: t('favor.step2')
        }, {
            id: "12",
            icon: Step3,
            alt: 'icon: step 3',
            text: t('favor.step3')
        }, {
            id: "13",
            icon: Step4,
            alt: 'icon: step 4',
            text: t('favor.step4')
        }
    ]

    return (
        <div className={`mb-140 ${style.wrapper}`} id="howTake">
            <h3 className={`h2 ${style.header}`}>{t('favor.header')}</h3>
            <p className={`p1 ${style.subtitle}`}>{t('favor.subtitle')}</p>
            <ul className={style.list}>
                {data.map(item => (
                    <li className={style.listItem} key={item.id}>
                        <div className={style.imgWrapper}>
                            <img className={style.icon} src={item.icon} alt={item.alt}/>
                        </div>
                        <p className={style.text}>{item.text}</p>
                    </li>
                ))}
            </ul>
            <ApplyBtn onClick={() => setValue(true)} btnName={t('favor.btn')}/>
        </div>
    );
};

export default Favor;