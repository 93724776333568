import React, {useState} from 'react';
import style from './BurgerBtn.module.sass';
import Menu from "../MobMenu/Menu";

const BurgerBtn = () => {
    const [active, setActive] = useState(false);

    return (
        <>
            <button className={`${style.menu} ${active ? style.active : ""}`} onClick={()=>setActive(!active)}>
                <span className={style.top}></span>
                <span className={style.middle}></span>
                <span className={style.bottom}></span>
            </button>
            { active ? <Menu closeFunc={setActive}/> : "" }
        </>
    );
};

export default BurgerBtn;