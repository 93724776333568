import React, {useContext} from 'react';
import style from "./Avantage.module.sass";
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../../App";
import ApplyBtn from "../../../UI/ApplyBtn";
import False from "../../../assets/img/icons/close-white.svg";
import True from "../../../assets/img/icons/done-white.svg";
import Arrow from "../../../assets/img/icons/arrowRight.svg";

const Advantage = () => {
    const {t, i18n} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    const plusData = [t('advantage.plus.one'), t('advantage.plus.two'), t('advantage.plus.three'), t('advantage.plus.four'), t('advantage.plus.five')];
    const minusData = [t('advantage.minus.one'), t('advantage.minus.two'), t('advantage.minus.three'), t('advantage.minus.four'), t('advantage.minus.five')];

    return (
        <div className={`mb-140 ${style.wrapper}`} id="advantage">
            <h3 className={`h2 ${style.header}`}>{t('advantage.header')}</h3>
            <div className={style.content}>
                <ul className={`${style.list} ${style.listRed}`}>
                    <li className={`${style.title} ${style.titleRed}`}>{t('advantage.minus.header')}</li>
                    {
                        minusData.map(item => (
                            <li className={style.item} key={item}>
                                <div className={`${style.imgWrapper} ${style.imgWrapperRed}`}>
                                    <img className={style.icon} alt="icon: false" src={False}/>
                                </div>
                                <p className={`p ${style.text}`}>{item}</p>
                            </li>
                        ))
                    }
                    <div className={style.arrowWrapper}>
                        <img className={style.arrow} src={Arrow} alt="icon: arrow"/>
                    </div>
                </ul>
                <ul className={style.list}>
                    <li className={`${style.title} ${style.titleGreen}`}>{t('advantage.plus.header')}</li>
                    {
                        plusData.map(item => (
                            <li className={style.item} key={item}>
                                <div className={`${style.imgWrapper} ${style.imgWrapperGreen}`}>
                                    <img className={style.icon} alt="icon: false" src={True}/>
                                </div>
                                <p className={`p ${style.text}`}>{item}</p>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <p className={`p ${style.subtitle}`}>
                {t('advantage.finallyText')}
            </p>
            <ApplyBtn btnName={t('advantage.btn')} onClick={() => setValue(true)}/>
        </div>
    );
};

export default Advantage;