import React from "react";

const InputField = ({label, value, onChange, errorText, isChanged, onBlur}) => {
    return (
        <div className="form_item">
            <label className="p2 form_item--label">{label}</label>
            <input className="form_item--input" value={value} onBlur={onBlur}
                   onChange={onChange}/>
            <p className="form_item--error">{isChanged && errorText ? errorText : ''}</p>
        </div>);
}

export default InputField;