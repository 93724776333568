import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Home, Services, Contacts, Documents, AboutCompany, ErrorPage} from "./pages/index";
import Layout from "./components/Layout";
import Auth from "./pages/Admin/Auth/Auth";
import Management from "./pages/Admin/Management/Management";
import Texpasport from "./pages/ServicePage/Texpasport";
import PrivZemlya from "./pages/ServicePage/PrivZemlya";
import ExpertnayaOtsinka from "./pages/ServicePage/ExpertnayaOtsinka";
import PrivDwelling from "./pages/ServicePage/PrivDwelling";
import VvodExpl from "./pages/ServicePage/VvodExpl";
import VostanovDoc from "./pages/ServicePage/VostanovDoc";
import NedvizIm from "./pages/ServicePage/NedvizIm";
import Links from "./pages/ServicePage/Links";

const Routing = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path="services" element={<Services/>}/>
                <Route path="services/1" element={<Texpasport/>}/>
                <Route path="services/2" element={<PrivZemlya/>}/>
                <Route path="services/3" element={<PrivDwelling/>}/>
                <Route path="services/4" element={<ExpertnayaOtsinka/>}/>
                <Route path="services/5" element={<VostanovDoc/>}/>
                <Route path="services/6" element={<VvodExpl/>}/>
                <Route path="services/7" element={<NedvizIm/>}/>
                <Route path="services/links" element={<Links/>}/>
                <Route path="documents" element={<Documents/>}/>
                <Route path="aboutCompany" element={<AboutCompany/>}/>
                <Route path="contacts" element={<Contacts/>}/>
                <Route path="admin" element={<Management/>}/>
                <Route path="lemma/2023/site-management/auth" element={<Auth/>}/>
                <Route path="*" element={<ErrorPage/>}/>
            </Route>
        </Routes>
    );
};

export default Routing;