import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../App";
import style from "./ServicePage.module.sass";
import Sidebar from "../../components/common/Sidebar/Sidebar";
import ApplyBtn from "../../UI/ApplyBtn";
import Promo2 from "../../components/Commercial/Promo2";

const PrivDwelling = () => {
    const {t} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    return (
        <div className="wrapper">
            <div className="container">
                <div className={style.container}>
                    <div className={style.wrapper}>
                        <Sidebar/>
                        <div className={style.content}>
                            <h1 className="h2">{t('privDom.header')}</h1>
                            <p className={style.text}>
                                {t('privDom.text1')}
                            </p>
                            <h6 className={style.title}>{t('privDom.title1')}</h6>
                            <ul className={`${style.list} ${style.mb25}`}>
                                <li>{t('privDom.list1.1')}</li>
                                <li>{t('privDom.list1.2')}</li>
                                <li>{t('privDom.list1.3')}</li>
                                <li>{t('privDom.list1.4')}</li>
                            </ul>
                            <p className={`${style.text} ${style.mb25}`}>
                                {t('privDom.text2')}
                            </p>
                            <div className={`${style.bannerServ} ${style.mb25}`}>
                                <h6>{t('privDom.title2')}</h6>
                            </div>
                        </div>
                    </div>
                    <p className={style.text3}>
                        {t('privDom.title3')}
                    </p>
                    <h3 className={style.title}>{t('privDom.title4')}</h3>
                    <ul className={`${style.list} ${style.mb25}`}>
                        <li>{t('privDom.list2.1')}</li>
                        <li>{t('privDom.list2.2')}</li>
                        <li>{t('privDom.list2.3')}</li>
                        <li>{t('privDom.list2.4')}</li>
                        <li>{t('privDom.list2.5')}</li>
                        <li>{t('privDom.list2.6')}</li>
                        <li>{t('privDom.list2.7')}</li>
                    </ul>
                    <p className={style.text}>
                        {t('privDom.text3')}
                    </p>
                </div>
                <Promo2/>
            </div>
        </div>
    );
};

export default PrivDwelling;