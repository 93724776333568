import React, {Fragment} from 'react';
import {useTranslation} from "react-i18next";
import CompanyInfo from '../components/AboutCompany/CompanyInfo';
import Banner from '../components/common/Banner/Banner';
import bgImage from '../assets/img/bg3.jpg'
import CompanyText from '../components/AboutCompany/CompanyText';
import Map from "../components/Contacts/Map";
import Benefits from "../components/common/Benefits/Benefits";
import Location from "../components/common/Location/Location";

const AboutCompany = () => {
    const {t, i18n} = useTranslation();

    return (
        <section className="wrapper">
            <Banner header={t('company.header')} bgImage={bgImage}/>
            <div className='container'>
                <CompanyInfo/>
                <CompanyText/>
                <Benefits/>
                <Location/>
            </div>
        </section>
    );
};

export default AboutCompany;