import React from 'react';
import style from "./ServicePage.module.sass";
import Sidebar from "../../components/common/Sidebar/Sidebar";
import Promo2 from "../../components/Commercial/Promo2";
import {useTranslation} from "react-i18next";

const PrivZemlya = () => {
    const {t} = useTranslation();

    return (
        <div className="wrapper">
            <div className="container">
                <div className={style.container}>
                    <div className={style.wrapper}>
                        <Sidebar/>
                        <div className={style.content}>
                            <h1 className="h2">{t('privatZm.header')}</h1>
                            <p className={style.text}>
                                <strong className={`${style.text2} ${style.textLg}`}>{t('privatZm.title')}</strong>
                                {t('privatZm.text1')}
                            </p>
                            <h6 className={style.title}>
                                {t('privatZm.title1')}
                            </h6>
                            <p className={style.text}>
                                {t('privatZm.text2')}
                            </p>
                            <div className={style.tableWrapper}>
                                <table className={style.table}>
                                    <tbody>
                                    <tr>
                                        <td>{t('privatZm.table.point1.name')}</td>
                                        <td>{t('privatZm.table.point1.text')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('privatZm.table.point2.name')}</td>
                                        <td>{t('privatZm.table.point2.text')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('privatZm.table.point3.name')}</td>
                                        <td>{t('privatZm.table.point3.text')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('privatZm.table.point4.name')}</td>
                                        <td>{t('privatZm.table.point4.text')}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <h6 className={style.title}>{t('privatZm.title2')}</h6>
                    <ul className={`${style.list} ${style.mb25}`}>
                        <li>{t('privatZm.list1.point1')}</li>
                        <li>{t('privatZm.list1.point2')}</li>
                        <li>{t('privatZm.list1.point3')}</li>
                        <li>{t('privatZm.list1.point4')}</li>
                        <li>{t('privatZm.list1.point5')}</li>
                        <li>{t('privatZm.list1.point6')}</li>
                        <li>{t('privatZm.list1.point7')}</li>
                        <li>{t('privatZm.list1.point8')}</li>
                        <li>{t('privatZm.list1.point9')}</li>
                    </ul>

                    <p className={`${style.text} ${style.mb25}`}>
                        {t('privatZm.text3')}
                    </p>
                    <p className={`${style.text} ${style.mb25}`}>
                        {t('privatZm.text4')}
                    </p>

                    <h2 className={style.titleCard}>
                        {t('privatZm.title3')}
                    </h2>

                    <h6 className={style.title}>{t('privatZm.title4')}</h6>
                    <ol className={style.listNum}>
                        <li>
                            <strong className={style.text3}>{t('privatZm.list2.point1.name')}</strong>
                            {t('privatZm.list2.point1.text')}
                        </li>
                        <li>
                            <strong className={style.text3}>{t('privatZm.list2.point2.name')}</strong>
                            {t('privatZm.list2.point2.text')}
                        </li>
                        <li>
                            <strong className={style.text3}>{t('privatZm.list2.point3.name')}</strong>
                            {t('privatZm.list2.point3.text')}
                        </li>
                        <p className={`${style.text} ${style.mb25}`}>
                            {t('privatZm.text5.part1')}
                            <strong className={style.text2}>
                                {t('privatZm.text5.partRed')}
                            </strong>
                            {t('privatZm.text5.part2')}
                        </p>
                        <p className={`${style.text} ${style.mb25}`}>
                            {t('privatZm.text6.part1')}
                            <strong className={style.text2}>
                                {t('privatZm.text6.partRed')}
                            </strong>
                        </p>
                        <li>
                            <strong className={style.text3}>
                                {t('privatZm.list2.point4.name')}
                            </strong>
                            {t('privatZm.list2.point4.text')}
                        </li>
                        <li><strong className={`${style.text3} ${style.mb25}`}>{t('privatZm.list2.point5.name')}</strong></li>
                        <p className={`${style.text} ${style.mb25}`}>{t('privatZm.list2.point5.text')}</p>
                        <ul className={style.list}>
                            <li>{t('privatZm.list3.point1')}</li>
                            <li>{t('privatZm.list3.point2')}</li>
                            <li>{t('privatZm.list3.point3')}</li>
                            <li>{t('privatZm.list3.point4')}</li>
                            <li>{t('privatZm.list3.point5')}</li>
                            <li>{t('privatZm.list3.point6')}</li>
                        </ul>
                    </ol>
                </div>
                <Promo2/>
            </div>
        </div>
    );
};

export default PrivZemlya;