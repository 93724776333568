import React, {useCallback, useEffect, useState} from 'react';
import style from './Management.module.sass';
import {useTranslation} from "react-i18next";
import {onAuthStateChanged, signOut} from "firebase/auth";
import {auth} from "../../../firebaseSDK";
import {useNavigate} from "react-router-dom";
import {deleteOrder, getOrder, updateOrder} from "../../../api";
import CheckBox from "./components/CheckBox";
import ErrorPage from "../../ErrorPage";
import CloseImg from "../../../assets/img/icons/close.svg";

const Management = () => {
    const [isAuth, setIsAuth] = useState(false);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [order, setOrder] = useState([])


    useEffect(() => {
        (async () => {
            const b = [];
            const res = await getOrder();
            res.forEach(doc => {
                b.push(doc.data());
            })
            setOrder(b);
        })()
    }, [])

    onAuthStateChanged(auth, (user) => {
        if (!user) {
            setIsAuth(false);
            // return navigate('/');
        } else {
            setIsAuth(true);
        }
    })

    return (
        <div className={`wrapper ${style.wrapper}`}>
            <div className="container">
                { isAuth ?
                 <>
                    <button className={style.btnRound} onClick={async () => {await signOut(auth);}}>Log Out</button>
                    <h2 className="h2">{t("admin.header")}</h2>
                    <ul className={style.list}>
                        {order.map(item => (
                            <li className={style.card} key={item.id}>
                                <button className="modal_btn--close" onClick={async () => {
                                    await deleteOrder(item.id);
                                    setOrder(order.filter((el) => el.id !== item.id))
                                }}>
                                    <img className="modal_btn--close-img" src={CloseImg} alt="icon: Close"/>
                                </button>
                                <div className={style.content}>
                                    <p className={style.text}><strong
                                        className={style.title}>{t("admin.name")}</strong><span>{item.name}</span></p>
                                    <p className={style.text}><strong className={style.title}>{t("admin.num")}</strong><a
                                        href={`tel:${item.phone}`} target="_blank">{item.phone}</a></p>
                                    <p className={`${style.text} ${style.sms}`}><strong
                                        className={style.title}>{t("admin.sms")}</strong><span>{item.message}</span></p>
                                </div>
                                <CheckBox id={item.id} checked={item.checked}/>
                            </li>
                        ))}
                    </ul>
                 </>
                    : <ErrorPage/>}
            </div>
        </div>

    );
};

export default Management;