import {collection, doc, setDoc, getDocs, updateDoc, deleteDoc} from "firebase/firestore";
import {db} from "./firebaseSDK";

export const sendForm = async ({name, phone, message}) => {
    const id = Date.now();
    const body = {
        id: id,
        name: name,
        phone: phone,
        message: message,
        checked: false,
    }
    return await setDoc(doc(db, `order/${id}`), body);
}

export const getOrder = async () => {
   try {
       return await getDocs(collection(db, 'order'));
   } catch (e) {
       return 'LOX'
   }
    // return await docRef(collection(db, 'order'));
}

export const updateOrder = async ({id, checked}) => {
    const body = {
        checked: checked,
    }
    return await updateDoc(doc(db, `order/${id}`), body);
}

export const deleteOrder = async (id) => {
    return await deleteDoc(doc(db, `order/${id}`));
}