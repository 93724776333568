import React, {useContext} from 'react';
import Nav from "./Nav";
import Logo from "./Logo";
import Contacts from "./Contacts";
import {useTranslation} from "react-i18next";
import ToggleLanguage from "./ToggleLanguage";
import ApplyBtn from "../../UI/ApplyBtn";
import {GlobalContext} from "../../App";
import BurgerBtn from "../MobMenu/BurgerBtn/BurgerBtn";

const Header = () => {
    const {t} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    return (
        <header className="header">
            <div className="container header-wrapper">
                <Logo/>
                <div className="header-content">
                    <div className="header-list underline">
                        <Contacts/>
                        <ToggleLanguage/>
                    </div>
                    <div className="header-list header-list--main">
                        <Nav/>
                        <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                    </div>
                    <BurgerBtn/>
                </div>
            </div>
        </header>
    );
};

export default Header;