import React from 'react';
import {useTranslation} from "react-i18next";

const Spinner = () => {
    const {t, i18n} = useTranslation();

    return (
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <p>{t('loading')}</p>
        </div>
    );
};

export default Spinner;