import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../App";
import style from "./ServicePage.module.sass";
import Sidebar from "../../components/common/Sidebar/Sidebar";
import ApplyBtn from "../../UI/ApplyBtn";
import Promo2 from "../../components/Commercial/Promo2";

const VostanovDoc = () => {
    const {t} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    return (
        <div className="wrapper">
            <div className="container">
                <div className={style.container}>
                    <div className={style.wrapper}>
                        <Sidebar/>
                        <div className={style.content}>
                            <h1 className="h2">{t('reDoc.header')}</h1>
                            <p className={`${style.title} ${style.mt0}`}>
                                {t('reDoc.title1')}
                            </p>
                            <ul className={`${style.list} ${style.mb25}`}>
                                <li>{t('reDoc.list1.1')}</li>
                                <li>{t('reDoc.list1.2')}</li>
                                <li>{t('reDoc.list1.3')}</li>
                                <li>{t('reDoc.list1.4')}</li>
                            </ul>
                            <p className={style.title}>
                                {t('reDoc.title2')}
                            </p>
                            <ul className={`${style.list} ${style.mb25}`}>
                                <li>{t('reDoc.list2.1')}</li>
                                <li>{t('reDoc.list2.2')}</li>
                                <li>{t('reDoc.list2.3')}</li>
                            </ul>
                            <p className={`${style.text3} ${style.mb25}`}>
                                {t('reDoc.title3')}
                            </p>
                        </div>
                    </div>
                    <p className={style.text}>
                        {t('reDoc.text1.part1')}
                        <stront className={style.text2}>{t('reDoc.text1.partRed')}</stront>
                        {t('reDoc.text1.part2')}
                    </p>
                </div>
                <Promo2/>
            </div>
        </div>
    );
};

export default VostanovDoc;