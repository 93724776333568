import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from './Header/Logo';
import EmailIcon from '../assets/img/icons/email.svg';
import PhoneIcon from '../assets/img/icons/phone.svg';
import FacebookIcon from '../assets/img/icons/facebook.svg';
import TelegramIcon from '../assets/img/icons/telegram.svg';
import ViberIcon from '../assets/img/icons/viber.svg';
import { NavLink } from 'react-router-dom';
import ArrowTop from '../assets/img/icons/arrow-top.svg';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [arrorIsActive, setArrorIsActive] = useState('');

  const startYear = 2022;
  const currentYear = new Date().getFullYear();

  const handlerScroll = () => {
    if (window.scrollY > 500) {
      setArrorIsActive('active');
    }
    if (window.scrollY <= 500) {
      setArrorIsActive('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll);
  }, []);

  const navLinks = [
    {
      href: '/',
      name: t('navMain'),
    },
    {
      href: '/services',
      name: t('navServices'),
    },
    {
      href: '/documents',
      name: t('navDocuments'),
    },
    {
      href: '/aboutCompany',
      name: t('navAboutCompany'),
    },
    {
      href: '/contacts',
      name: t('navContacts'),
    },
  ];

  const contactsList = [
    {
      href: `mailto:${t('email')}`,
      img: EmailIcon,
      name: t('email'),
      title: t('title.hoverEmail'),
    },
    {
      href: `tel:${t('phone1')}`,
      img: PhoneIcon,
      name: t('phone1'),
      title: t('title.honerNum'),
    },
  ];

  return (
    <div className="footer">
      <div className="container footer_container">
        <div className="footer_wrapper">
          <Logo classItem={'footer_logo'} classImg={'footer_logoImg'} />
          <ul className="footer_nav footer_nav-links">
            <h4 className="footer_header">{t('nav')}</h4>
            {navLinks.map((item) => (
              <li className="footer_nav--item" key={item.name}>
                <NavLink className="footer_nav--link" to={item.href}>
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="footer_nav footer_nav-contacts">
            <h4 className="footer_header">{t('navContacts')}</h4>
            {contactsList.map((item) => (
              <li className="footer_nav--item" key={item.img}>
                <a
                  className="footer_nav--link"
                  href={item.href}
                  target="_blank"
                  title={item.title}
                >
                  {item.name}
                </a>
              </li>
            ))}
            <ul className="footer_icons">
              <li className="footer_icons--item">
                <a
                  className="footer_icons--link"
                  href="https://www.facebook.com/officelemma"
                  target="_blank"
                  title="Facebook"
                >
                  <img
                    className="footer_icons--img"
                    src={FacebookIcon}
                    alt="Facebook"
                  />
                </a>
              </li>
              <li className="footer_icons--item">
                <a
                  className="footer_icons--link"
                  href="https://t.me/lemma_ua"
                  target="_blank"
                  title="Telegram"
                >
                  <img
                    className="footer_icons--img"
                    src={TelegramIcon}
                    alt="Telegram"
                  />
                </a>
              </li>
              <li className="footer_icons--item">
                <a
                  className="footer_icons--link"
                  href="viber://chat?number=%2B380955500525"
                  target="_blank"
                  title="Viber"
                >
                  <img
                    className="footer_icons--img"
                    src={ViberIcon}
                    alt="Viber"
                  />
                </a>
              </li>
            </ul>
          </ul>
          <div className="footer_map">
            <h4 className="footer_header">ПРАВНИЧЕ БЮРО НЕРУХОМОСТІ "ЛЕММА"</h4>
            <iframe
              className="footer_map--frame"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7027.472593357877!2d36.21569400390616!3d49.99193153031554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4127a0f90d07213d%3A0x54e6dfcae400438a!2sKatsars&#39;ka%20St%2C%2011%2C%20Kharkiv%2C%20Kharkivs&#39;ka%20oblast%2C%2061000!5e0!3m2!1sen!2sua!4v1673610263984!5m2!1sen!2sua"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <button
            className={`footer_arrow ${arrorIsActive}`}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <img
              className="footer_arrow--img"
              alt="icon: arrow top"
              src={ArrowTop}
            />
          </button>
        </div>
        <div className="footer_bottom">
          <p className="footer_bottom--text">
            Copyrights © {startYear} - {currentYear} Lemma
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
