import React from 'react';
import {useTranslation} from "react-i18next";
import LocationIcon from "../../assets/img/icons/location.svg"
import EmailIcon from "../../assets/img/icons/email.svg"
import PhoneIcon from "../../assets/img/icons/phone.svg"
import TimeIcon from "../../assets/img/icons/time.svg"
import {NavLink} from "react-router-dom";

const Contacts = () => {
    const {t, i18n} = useTranslation();

    const contactsList = [
        {
            href: "https://goo.gl/maps/ctcVJK2CyAjuUF7M7",
            img: LocationIcon,
            name: t('locationMain'),
            title: t('title.hoverLocation')
        },
        {
            href: `mailto:${t('email')}`,
            img: EmailIcon,
            name: t('email'),
            title: t('title.hoverEmail')
        },
        {
            href: `tel:${t('phone1')}`,
            img: PhoneIcon,
            name: t('phone1'),
            title: t('title.honerNum')
        },
    ]

    return (
        <ul className="header_contacts">
            {
                contactsList.map(item => (
                    <li className="header_contacts--item" key={item.img}>
                        <a className="header_contacts--link" href={item.href} target="_blank" title={item.title}>
                            <img src={item.img} alt="icon: Location"/>
                            <strong>{item.name}</strong>
                        </a>
                    </li>
                ))
            }

            <li className="header_contacts--item">
                <NavLink className="header_contacts--link" to="#" target="_blank" title="График работы" onClick={(e)=>{e.preventDefault()}}>
                    <img src={TimeIcon} alt="icon: Location"/>
                    <strong>ПН-ПТ, 09:00 - 18:00</strong>
                </NavLink>
            </li>
        </ul>
    );
};

export default Contacts;