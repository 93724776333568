import React from 'react';
import Banner from "../components/Home/Banner";
import ServicesList from "../components/Home/ServicesList";
import Promo1 from "../components/Commercial/Promo1";
import Promo2 from "../components/Commercial/Promo2";
import Favor from "../components/common/Favor/Favor";
import Advantage from "../components/common/Advantage/Advantage";
import Benefits from "../components/common/Benefits/Benefits";
import Networks from "../components/Contacts/networks";
import Location from "../components/common/Location/Location";

const Home = () => {
    return (
        <section className="wrapper">
            <Banner/>
            <div className="container">
                <ServicesList/>
                <Favor/>
                <Promo2/>
                <Benefits checkBtn={false}/>
                <Advantage/>
                <Networks/>
                <Promo1/>
                <Location/>
            </div>
        </section>
    );
};

export default Home;