import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IMask from 'imask';
import InputField from './InputField';
import { sendForm } from '../../api';
import logo from '../Header/Logo';
import { sendEmail } from './sendEmail';

const Form = ({ setSuccess }) => {
  const { t, i18n } = useTranslation();
  const nameValidText = t('form.errorName');
  const phoneValidText = t('form.errorPhone');
  const phoneValidText2 = t('form.errorPhone2');
  const [nameData, setNameData] = useState({
    value: '',
    error: nameValidText,
    isChanged: false,
  });
  const [phoneData, setPhoneData] = useState({
    value: '',
    error: phoneValidText,
    isChanged: false,
  });
  const [message, setMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [formSent, setFormSent] = useState(false);

  useEffect(() => {
    if (nameData.error || phoneData.error) return setIsValid(false);
    setIsValid(true);
  }, [nameData.error, phoneData.error]);

  const changeName = (value) => {
    setNameData({ value: value, error: nameValidText, isChanged: true });
    if (!value)
      return setNameData({
        value: value,
        error: nameValidText,
        isChanged: true,
      });
    setNameData({ value: value, error: '', isChanged: true });
  };

  const changePhone = (value) => {
    console.log(phoneValidText2);
    console.log(value.length);
    setPhoneData({ value: value, error: nameValidText, isChanged: false });
    if (!value)
      return setPhoneData({
        value: value,
        error: phoneValidText,
        isChanged: false,
      });
    if (value.length < 18)
      setPhoneData({ value: value, error: phoneValidText2, isChanged: false });
    setPhoneData({ value: value, error: '', isChanged: true });
  };

  const phoneMask = (inputElement) => {
    IMask(inputElement, {
      mask: '+{38}(000) 000-00-00',
    });
  };

  const onBlurPhone = (e) => {
    if (!e.target.value) {
      return setPhoneData({
        value: e.target.value,
        error: phoneValidText,
        isChanged: true,
      });
    } else if (e.target.value.length < 18) {
      return setPhoneData({
        value: e.target.value,
        error: phoneValidText2,
        isChanged: true,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSent(true);
    setSuccess(true);
  };

  return (
    <>
      {formSent ? (
        <p>Ваша заявка отправлена!</p>
      ) : (
        <form className="form" onSubmit={handleSubmit}>
          <InputField
            label={t('form.name')}
            value={nameData.value}
            onChange={(e) => changeName(e.target.value)}
            isChanged={nameData.isChanged}
            errorText={nameData.error}
          />
          <InputField
            label={t('form.phone')}
            value={phoneData.value}
            onBlur={onBlurPhone}
            onChange={(e) => {
              phoneMask(e.target);
              changePhone(e.target.value);
            }}
            isChanged={phoneData.isChanged}
            errorText={phoneData.error}
          />
          <InputField
            label={t('form.message')}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <button
            className="btn-apply form_btn--apply"
            onClick={(e) => {
              e.preventDefault();
              sendEmail();
              setSuccess(true);
              sendForm({
                name: nameData.value,
                phone: phoneData.value,
                message: message,
              }).then((r) => console.log('success'));
            }}
            disabled={!isValid}
          >
            {t('form.btn')}
          </button>
        </form>
      )}
    </>
  );
};

export default Form;
