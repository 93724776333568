import React from 'react';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '../../assets/img/icons/facebook.svg';
import TelegramIcon from '../../assets/img/icons/telegram.svg';
import ViberIcon from '../../assets/img/icons/viber.svg';

const Networks = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="contacts-networks" id="contacts">
      <h2 className="h3">{t('contacts.headerNetworks')}</h2>
      <ul className="contacts-networks_list">
        <li className="contacts-networks_list--item">
          <a
            className="contacts-networks_list--link"
            href="https://www.facebook.com/officelemma"
            target="_blank"
            title="Facebook"
          >
            <img
              className="contacts-networks_list--img"
              src={FacebookIcon}
              alt="Facebook"
            />
            <span>Facebook</span>
          </a>
        </li>
        <li className="contacts-networks_list--item">
          <a
            className="contacts-networks_list--link"
            href="https://t.me/lemma_ua"
            target="_blank"
            title="Telegram"
          >
            <img
              className="contacts-networks_list--img"
              src={TelegramIcon}
              alt="Telegram"
            />
            <span>Telegram</span>
          </a>
        </li>
        <li className="contacts-networks_list--item">
          <a
            className="contacts-networks_list--link"
            href="viber://chat?number=%2B380955500525"
            target="_blank"
            title="Viber"
          >
            <img
              className="contacts-networks_list--img"
              src={ViberIcon}
              alt="Viber"
            />
            <span>Viber</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Networks;
