import React from 'react';
import style from './Sidebar.module.sass';
import Li from "./Li";
import ListLi from "./ListLi";
import {useTranslation} from "react-i18next";

const Sidebar = () => {
    const {t} = useTranslation();
    const list1 = [{name: 'itemList1', link: '#'}, {name: 'itemList3', link: '#'}, {name: 'itemList4', link: '#'}];

    return (
        <aside className={style.wrapper}>
            <h4 className={`h5 ${style.header}`}>{t('navServices')}</h4>
            <ul>
                <Li link={"/services/1"} name={t('services.list.texPasport')}/>
                <Li link={"/services/2"} name={t('services.list.privZ')}/>
                <Li link={"/services/3"} name={t('services.list.privG')}/>
                <Li link={"/services/4"} name={t('services.list.expertOtsinka')}/>
                <Li link={"/services/5"} name={t('services.list.vvodVExp')}/>
                <Li link={"/services/6"} name={t('services.list.uzakStroyit')}/>
                <Li link={"/services/7"} name={t('services.list.uzakPerepl')}/>
                <Li link={"/services/links"} name={t('services.list.links')}/>
            </ul>
        </aside>
    );
};

export default Sidebar;