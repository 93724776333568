import React from 'react';
import style from './Banner.module.sass';

const Banner = ({header, bgImage}) => {
    return (
        <div className={style.banner}>
            <img className={style.img} src={bgImage} alt="img: background banner" title={header}/>
            <div className={`container ${style.container}`}>
                <div>
                    <h2 className={`h4 ${style.header}`}>{header}</h2>
                    <hr className="home_banner--line"/>
                </div>
            </div>
        </div>
    );
};

export default Banner;
