import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../App";
import style from "./ServicePage.module.sass";
import Sidebar from "../../components/common/Sidebar/Sidebar";
import Promo2 from "../../components/Commercial/Promo2";

const NedvizIm = () => {
    const {t} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    return (
        <div className="wrapper">
            <div className="container">
                <div className={style.container}>
                    <div className={`${style.wrapper} ${style.mb25}`}>
                        <Sidebar/>
                        <div className={style.content}>
                            <h1 className="h2">{t('rozdil.header')}</h1>
                            <p className={`${style.text} ${style.mb25}`}>
                                <strong className={style.text2}>{t('rozdil.title1')}</strong>
                                {t('rozdil.text1')}
                            </p>
                            <p className={`${style.text} ${style.mb25}`}>
                                {t('rozdil.text2')}
                            </p>
                            <p className={`${style.text} ${style.mb25}`}>
                                {t('rozdil.text3')}
                            </p>
                            <p className={`${style.text} ${style.mb25}`}>
                                {t('rozdil.text4')}
                            </p>
                        </div>
                    </div>
                    <p className={`${style.text} ${style.mb25}`}>
                        {t('rozdil.text5.part1')} <strong className={style.text3}>{t('rozdil.text5.part2')}</strong> {t('rozdil.text5.part3')}<br/>
                        <a className={style.text3} href="https://zakon.rada.gov.ua/laws/show/z0774-07#Text" target="_blank">https://zakon.rada.gov.ua/laws/show/z0774-07#Text</a>
                    </p>
                    <p className={`${style.text} ${style.mb25}`}>
                        {t('rozdil.text6')}
                    </p>
                    <div className={`${style.bannerServ} ${style.mb25}`}>
                        <h6>{t('rozdil.title2')}</h6>
                    </div>
                </div>
                <Promo2/>
            </div>
        </div>
    );
};

export default NedvizIm;