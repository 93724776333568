import React, {useContext} from 'react';
import Sidebar from "../../components/common/Sidebar/Sidebar";
import style from "./ServicePage.module.sass";
import Promo2 from "../../components/Commercial/Promo2";
import ApplyBtn from "../../UI/ApplyBtn";
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../App";

const Texpasport = () => {
    const {t} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    return (
        <div className="wrapper">
            <div className="container">
                <div className={style.container}>
                    <div className={style.wrapper}>
                        <Sidebar/>
                        <div className={style.content}>
                            <h1 className="h2">{t('texPassport.header')}</h1>
                            <p className={`${style.text} ${style.mb25}`}>
                                <strong className={`${style.text2} ${style.textLg}`}>{t('texPassport.title')}</strong>
                                {t('texPassport.subtitle')}
                            </p>
                            <p className={style.text}>
                                {t('texPassport.text1')}
                            </p>
                            <h6 className={style.title}>
                                {t('texPassport.title1')}
                            </h6>
                            <ul className={style.list}>
                                <li>{t('texPassport.list1.1')}</li>
                                <li>{t('texPassport.list1.2')}</li>
                                <li>{t('texPassport.list1.3')}</li>
                                <li>{t('texPassport.list1.4')}</li>
                                <li>{t('texPassport.list1.5')}</li>
                                <li>{t('texPassport.list1.6')}</li>
                                <li>{t('texPassport.list1.7')}</li>
                                <li>{t('texPassport.list1.8')}</li>
                            </ul>
                        </div>
                    </div>
                    <ul className={style.cards}>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('texPassport.price.list1.name')}</h6>
                            <p className={style.text}>{t('texPassport.price.list1.day')}</p>
                            <strong className={style.textSm}>{t('texPassport.price.list1.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('texPassport.price.list2.name')}</h6>
                            <p className={style.text}>{t('texPassport.price.list2.day')}</p>
                            <strong className={style.textSm}>{t('texPassport.price.list2.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('texPassport.price.list3.name')}</h6>
                            <p className={style.text}>{t('texPassport.price.list3.day')}</p>
                            <strong className={style.textSm}>{t('texPassport.price.list3.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('texPassport.price.list4.name')}</h6>
                            <p className={style.text}>{t('texPassport.price.list4.day')}</p>
                            <strong className={style.textSm}>{t('texPassport.price.list4.price')}<sup>{t('texPassport.price.list4.priceSup')}</sup>)</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                        <li className={style.card}>
                            <h6 className={style.titleCard}>{t('texPassport.price.list5.name')}</h6>
                            <p className={style.text}>{t('texPassport.price.list5.day')}</p>
                            <strong className={style.textSm}>{t('texPassport.price.list5.price')}</strong>
                            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
                        </li>
                    </ul>
                    <h3 className={style.title}>{t('texPassport.title2')}</h3>
                    <ul className={style.list}>
                        <li><strong className={style.text3}>{t('texPassport.list2.point1.name')}</strong>{t('texPassport.list2.point1.text')}</li>
                        <li><strong className={style.text3}>{t('texPassport.list2.point2.name')}</strong>{t('texPassport.list2.point2.text')}</li>
                        <li><strong className={style.text3}>{t('texPassport.list2.point3.name')}</strong>{t('texPassport.list2.point3.text')}</li>
                        <li><strong className={style.text3}>{t('texPassport.list2.point4.name')}</strong>{t('texPassport.list2.point4.text')}</li>
                    </ul>
                    <h6 className={style.title}>{t('texPassport.title3')}</h6>
                    <ul className={`${style.list} ${style.mb25}`}>
                        <li>
                            <strong className={style.text3}>{t('texPassport.list3.point1.name')}</strong>
                            <p>{t('texPassport.list3.point1.text')}</p>
                        </li>
                        <li>
                            <strong className={style.text3}>{t('texPassport.list3.point2.name')}</strong>
                            <p>{t('texPassport.list3.point2.text')}</p>
                        </li>
                    </ul>
                    <p className={style.text}>
                        {t('texPassport.text2')}
                    </p>
                    <div className={style.tableWrapper}>
                        <table className={style.table}>
                            <tbody>
                                <tr>
                                    <td>{t('texPassport.table.point1.name')}</td>
                                    <td>{t('texPassport.table.point1.text')}</td>
                                </tr>
                                <tr>
                                    <td>{t('texPassport.table.point2.name')}</td>
                                    <td>{t('texPassport.table.point2.text')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className={`${style.text} ${style.mb25}`}>
                        {t('texPassport.text3')}
                    </p>
                </div>
                <Promo2/>
            </div>
        </div>
    );
};

export default Texpasport;