import React from 'react';
import bgImage from "../assets/img/bg2.jpg";
import Banner from "../components/common/Banner/Banner";
import {useTranslation} from "react-i18next";
import ServiceCard from "../components/Services/Card/ServiceCard";
import Favor from "../components/common/Favor/Favor";
import Location from "../components/common/Location/Location";

const Services = () => {
    const {t, i18n} = useTranslation();

    return (
        <section className="wrapper">
            <Banner header={t('navServices')} bgImage={bgImage}/>
            <div className="container">
                <ServiceCard/>
                <Favor/>
                <Location/>
            </div>
        </section>
    );
};

export default Services;