import React, { useContext } from 'react';
import style from './Menu.module.sass';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TimeIcon from '../../../assets/img/icons/time.svg';
import LocationIcon from '../../../assets/img/icons/location.svg';
import EmailIcon from '../../../assets/img/icons/email.svg';
import PhoneIcon from '../../../assets/img/icons/phone.svg';
import ToggleLanguage from '../../Header/ToggleLanguage';
import FacebookIcon from '../../../assets/img/icons/facebook-bw.svg';
import TelegramIcon from '../../../assets/img/icons/telegram-bw.svg';
import ViberIcon from '../../../assets/img/icons/viber-bw.svg';
import ApplyBtn from '../../../UI/ApplyBtn';
import { GlobalContext } from '../../../App';

const Menu = ({ closeFunc }) => {
  const { t, i18n } = useTranslation();
  const {
    modal: { setValue },
  } = useContext(GlobalContext);

  const navLinks = [
    {
      href: '/',
      name: t('navMain'),
    },
    {
      href: '/services',
      name: t('navServices'),
    },
    {
      href: '/documents',
      name: t('navDocuments'),
    },
    {
      href: '/aboutCompany',
      name: t('navAboutCompany'),
    },
    {
      href: '/contacts',
      name: t('navContacts'),
    },
  ];

  const contactsList = [
    {
      href: `tel:${t('phone1')}`,
      img: PhoneIcon,
      name: t('phone1'),
      title: t('title.honerNum'),
    },
    {
      href: `mailto:${t('email')}`,
      img: EmailIcon,
      name: t('email'),
      title: t('title.hoverEmail'),
      class: 'mb--3',
    },
    {
      href: 'https://goo.gl/maps/ctcVJK2CyAjuUF7M7',
      img: LocationIcon,
      name: t('locationMain'),
      title: t('title.hoverLocation'),
    },
  ];

  return (
    <div className={style.wrapper} onClick={() => closeFunc(false)}>
      <div className={style.menu} onClick={(e) => e.stopPropagation()}>
        <div className={style.flex}>
          <h6 className={style.header}>Меню</h6>
          <ToggleLanguage />
        </div>
        <ul className={style.nav}>
          {navLinks.map((item) => (
            <li
              className={style.navItem}
              key={item.href}
              onClick={() => closeFunc(false)}
            >
              <NavLink className={style.link} to={item.href}>
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>

        <ApplyBtn
          classBtn={'m-30'}
          btnName={t('apply')}
          onClick={() => setValue(true)}
        />

        <ul className={style.info}>
          <li>
            <p className={style.infoLink} title="График работы">
              <img src={TimeIcon} alt="icon: Location" />
              <strong>ПН-ПТ, 09:00 - 18:00</strong>
            </p>
          </li>

          {contactsList.map((item) => (
            <li key={item.img}>
              <a
                className={style.infoLink}
                target="_blank"
                title={item.title}
                href={item.href}
              >
                <img
                  className={item?.class ? item.class : ''}
                  src={item.img}
                  alt={`icon: ${item.name}`}
                />
                <strong>{item.name}</strong>
              </a>
            </li>
          ))}
        </ul>

        <ul className={style.networks}>
          <li className={style.networksItem}>
            <a
              className={style.networksLink}
              href="https://www.facebook.com/officelemma"
              target="_blank"
              title="Facebook"
            >
              <img
                className={style.networksImg}
                src={FacebookIcon}
                alt="Facebook"
              />
            </a>
          </li>
          <li className={style.networksItem}>
            <a
              className={style.networksLink}
              href="https://t.me/lemma_ua"
              target="_blank"
              title="Telegram"
            >
              <img
                className={style.networksImg}
                src={TelegramIcon}
                alt="Telegram"
              />
            </a>
          </li>
          <li className={style.networksItem}>
            <a
              className={style.networksLink}
              href="viber://chat?number=%2B380955500525"
              target="_blank"
              title="Viber"
            >
              <img className={style.networksImg} src={ViberIcon} alt="Viber" />
            </a>
          </li>
        </ul>

        <div className={style.footer}>Copyrights 2022 © Lemma</div>
      </div>
    </div>
  );
};

export default Menu;
