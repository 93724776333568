import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ErrorPage = () => {

    const {t, i18n} = useTranslation();

    return (
        <section className="wrapper error-wrapper">
            <div className="container error-container">
                <div className="error-content">
                    <p className="error-text">Page 404</p>
                    <Link className="btn-apply error-btn" to="/">{t('navMain')}</Link>
                </div>
            </div>
        </section>
    );
};

export default ErrorPage;