import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import PromoImg from "../../assets/img/promo_img2.png";
import style from "./Promo.module.sass";
import ApplyBtn from "../../UI/ApplyBtn";
import {GlobalContext} from "../../App";

const Promo2 = () => {
    const {modal: {setValue}} = useContext(GlobalContext);
    const {t, i18n} = useTranslation();

    return (
        <div className={`mb-140 ${style.wrapperRw}`}>
            <img className={style.imgRw} src={PromoImg} alt="img: documents"/>
            <div className={style.content}>
                <h4 className={`${style.header} ${style.gray} ${style.shadow}`}>{t('promo2.header')}</h4>
                <p className={style.text}>{t('promo2.text')}</p>
                <ApplyBtn btnName={t('promo2.btn')} onClick={() => setValue(true)}/>
            </div>
        </div>
    );
};

export default Promo2;