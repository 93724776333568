import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

const ToggleLanguage = () => {
    const {t, i18n} = useTranslation();
    const initState = t("lang") === "ua" ? {button_ru: true, button_ua: false} : {button_ru: true, button_ua: false}

    const [active, setActive] = useState(initState);

    const checkingLanguage = (activeLang) => {
        return activeLang === 'ua' ?
            {
                button_ru: true,
                button_ua: false,
            } : {
                button_ru: false,
                button_ua: true
            };
    }

    const changeLanguage = async (language) => {
        await i18n.changeLanguage(language);
        setActive(checkingLanguage(language));
    }

    useEffect(() => {
        setActive(checkingLanguage(i18n.language));
    }, [i18n])

    return (
        <div title={t('title.changeLang')}>
            <button className={`btn-small ${active.button_ua ? 'active' : ''}`} onClick={() => {
                changeLanguage('ua');
            }}>
                ua
            </button>
            <button className={`btn-small ${active.button_ru && 'active'}`} onClick={() => {
                changeLanguage('ru')
            }}>
                ru
            </button>
        </div>
    );
};

export default ToggleLanguage;