import React from 'react';
import {Link} from "react-router-dom";
import style from "./Services.module.sass";
import Img1 from '../../../assets/img/icons/documents1.svg';
import Img2 from '../../../assets/img/icons/documents2.svg';
import Img3 from '../../../assets/img/icons/documents3.svg';
import Img4 from '../../../assets/img/icons/home.svg';
import Img5 from '../../../assets/img/icons/home1.svg';
import Img6 from '../../../assets/img/icons/home2.svg';
import Img7 from '../../../assets/img/icons/doc1.svg';
import Img8 from '../../../assets/img/icons/doc2.svg';
import {useTranslation} from "react-i18next";

const ServiceCard = () => {
    const {t} = useTranslation();

    const data = [
        {
            id: "0",
            img: Img7,
            alt: "icon: documents",
            name: t('services.list.texPasport'),
            link: "/services/1",
        },
        {
            id: "1",
            img: Img3,
            alt: "icon: documents",
            name: t('services.list.privZ'),
            link: "/services/2",
        },
        {
            id: "2",
            img: Img4,
            alt: "icon: documents",
            name: t('services.list.privG'),
            link: "/services/3",
        },
        {
            id: "3",
            img: Img5,
            alt: "icon: documents",
            name: t('services.list.expertOtsinka'),
            link: "/services/4",
        },
        {
            id: "4",
            img: Img6,
            alt: "icon: documents",
            name: t('services.list.vvodVExp'),
            link: "/services/5",
        },
        {
            id: "5",
            img: Img1,
            alt: "icon: documents",
            name: t('services.list.uzakStroyit'),
            link: "/services/6",
        },
        {
            id: "6",
            img: Img8,
            alt: "icon: documents",
            name: t('services.list.uzakPerepl'),
            link: "/services/7",
        },
        {
            id: "7",
            img: Img2,
            alt: "icon: documents",
            name: t('services.list.links'),
            link: "/services/links",
        }
    ]

    return (
        <ul className={style.list} id="service">
            {data.map(item=>(
                <Link className={style.card} to={item.link} key={item.id}>
                    <div className={style.imgWrapper}>
                        <img className={style.img} src={item.img} alt={item.alt}/>
                    </div>
                    <h4 className={style.title}>{item.name}</h4>
                </Link>
            ))}
        </ul>
    );
};

export default ServiceCard;