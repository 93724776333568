import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {GlobalContext} from "../../App";
import ApplyBtn from "../../UI/ApplyBtn";
import style from "./Promo.module.sass";

const Promo1 = () => {
    const {t, i18n} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    return (
        <div className={style.wrapperCl}>
            <h2 className={style.header}>{t('contacts.headerConnection')}</h2>
            <p className={style.text}>{t('contacts.textConnection')}</p>
            <ApplyBtn btnName={t('apply')} onClick={() => setValue(true)}/>
        </div>
    );
};

export default Promo1;