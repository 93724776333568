import React from 'react';
import {useTranslation} from "react-i18next";
import style from './TabsBody.module.sass';

const TabHouse = () => {
    const {t, i18n} = useTranslation();
    return (
        <>
            <h4 className={style.header}>{t('tabHouse.header1')}</h4>
            <ol className={style.list}>
                <li className={style.item}>{t('tabHouse.list1.item1')}</li>
                <li className={style.item}>{t('tabHouse.list1.item2')}</li>
                <li className={style.item}>{t('tabHouse.list1.item3')}</li>
                <li className={style.item}>{t('tabHouse.list1.item4')}</li>
                <li className={style.item}>{t('tabHouse.list1.item5')}</li>
            </ol>
            <h4 className={style.header}>{t('tabHouse.header2')}</h4>
            <ol className={style.list}>
                <li className={style.item}>{t('tabHouse.list2.item1')}</li>
                <li className={style.item}>{t('tabHouse.list2.item2')}</li>
                <li className={style.item}>{t('tabHouse.list2.item3')}</li>
                <li className={style.item}>{t('tabHouse.list2.item4')}</li>
                <li className={style.item}>{t('tabHouse.list2.item5')}</li>
                <li className={style.item}>{t('tabHouse.list2.item6')}</li>
                <li className={style.item}>{t('tabHouse.list2.item7')}</li>
            </ol>
            <h4 className={style.header}>{t('tabHouse.header3')}</h4>
            <ol className={style.list}>
                <li className={style.item}>{t('tabHouse.list3.item1')}</li>
                <li className={style.item}>{t('tabHouse.list3.item2')}</li>
                <li className={style.item}>{t('tabHouse.list3.item3')}</li>
                <li className={style.item}>{t('tabHouse.list3.item4')}</li>
                <li className={style.item}>{t('tabHouse.list3.item5')}</li>
                <li className={style.item}>{t('tabHouse.list3.item6')}</li>
                <li className={style.item}>{t('tabHouse.list3.item7')}</li>
            </ol>
        </>
    );
};

export default TabHouse;