import React from 'react';
import {NavLink} from "react-router-dom";
import LogoImg from "../../assets/img/logo.png";
import {useTranslation} from "react-i18next";

const Logo = ({classImg = "", classItem=""}) => {
    const {t, i18n} = useTranslation();
    return (
        <NavLink className={`logo-wrapper ${classItem}`} to="/" title={t('navMain')}>
            <img className={`logo ${classImg}`} src={LogoImg} alt="img: Logo"/>
        </NavLink>
    );
};

export default Logo;