import React, {useContext, useEffect, useState} from 'react';
import style from './Auth.module.sass';
import { signInWithEmailAndPassword, onAuthStateChanged} from "firebase/auth";
import {auth} from "../../../firebaseSDK";
import {Route, useNavigate} from "react-router-dom";

const Auth = () => {
    const navigate = useNavigate();
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    console.log("error>>>", error)



    onAuthStateChanged(auth, (user) => {
        if (user) {
            return navigate('/admin');
        }
    })

    const authEmailAndPassword = async (login, password) => {

        const singIn = ()=> signInWithEmailAndPassword(auth, login, password)
            .then((userCredential) => {
                const user = userCredential.user;
            })
            .catch((error) => {
                const errorCode = error.code;
                errorCode? setError(errorCode):setError("");
                const errorMessage = error.message;
                errorMessage? setError(errorMessage):setError("");
                console.log(error.message);
            });
        singIn()
    };

    return (
        <div className={`wrapper ${style.wrapper}`}>
            <div className={`container ${style.container}`}>
                <form className={style.form}>
                    <h6 className="h2">Authorization</h6>
                    <label className={style.label}>
                        Login
                        <input className={style.input} value={login} onChange={(e)=>setLogin(e.target.value)}/>
                    </label>
                    <label className={style.label}>
                        Password
                        <input className={style.input} value={password} onChange={(e)=>setPassword(e.target.value)}/>
                    </label>
                    {error? <p className="p1">{error}</p> : ""}

                    <button type="button" className="btn-apply mt-30" onClick={()=>authEmailAndPassword(login, password)}>Send</button>
                </form>
            </div>
        </div>
    );
};

export default Auth;