import React, { Fragment } from "react";
import {useTranslation} from "react-i18next";

const CompanyText = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="mb-90">
      <p className="p1">
          {t('company.text1')}
      </p>
      <ul className="text-list p1">
        <li>Mauris finibus malesuada lobortis.</li>
        <li>Mauris finibus malesuada lobortis.</li>
        <li>Mauris finibus malesuada lobortis.</li>
        <li>Mauris finibus malesuada lobortis.</li>
      </ul>      
      <p className="p1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris iaculis,
        massa non malesuada blandit, nisi ex malesuada tortor, bibendum lobortis
        ex lectus id sem. Suspendisse vulputate ligula quis elit condimentum
        convallis. Morbi placerat rutrum posuere. Duis et congue lorem. Nulla
        vitae ornare lacus. Fusce ut nisl eu augue mollis consectetur. Lorem
        ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vehicula
        ac massa a laoreet. Integer sapien tortor, lobortis sit amet gravida
        non, pellentesque eu nibh. Mauris finibus malesuada lobortis.
      </p>
    </div>
  );
};

export default CompanyText;
