import React from 'react';
import style from './Sidebar.module.sass';
import {Link} from "react-router-dom";

const Li = ({link, name}) => {
    return (
        <li className={style.item}>
            <Link className={style.link} to={link}>{name}</Link>
        </li>
    );
};

export default Li;