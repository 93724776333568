import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import style from './Tabs.module.sass';
import TabFlat from "../TabBody/TabFlat";
import TabGarage from "../TabBody/TabGarage";
import TabHouse from "../TabBody/TabHouse";
import TabUrOsobam from "../TabBody/TabUrOsobam";

const Tabs = () => {
    const {t, i18n} = useTranslation();

    const tabNames = {
        flat: 'flat',
        house: 'house',
        urOsobam: 'urOsobam',
        garage: 'garage'
    }
    const initialTabs = {
        flat: false,
        house: false,
        urOsobam: false,
        garage: false
    }
    const [tab, setTab] = useState(initialTabs);

    const handlerSetTab = (tabName) => {
        setTab({...initialTabs, [tabName]: true});
    }

    useEffect(() => {
        handlerSetTab('flat');
    }, [])

    return (
        <div>
            <ul className={style.tabs}>
                <li className={`${style.tab} ${tab.flat === true ? style.active : ''}`}
                    onClick={() => handlerSetTab(tabNames.flat)}>
                    <button className={style.btn}>{t('tab.tabFlat')}</button>
                </li>
                <li className={`${style.tab} ${tab.house === true ? style.active : ''}`}
                    onClick={() => handlerSetTab(tabNames.house)}>
                    <button className={style.btn}>{t('tab.tabHouse')}</button>
                </li>
                <li className={`${style.tab} ${tab.garage === true ? style.active : ''}`}
                    onClick={() => handlerSetTab(tabNames.garage)}>
                    <button className={style.btn}>{t('tab.tabGarage')}</button>
                </li>
                <li className={`${style.tab} ${tab.urOsobam === true ? style.active : ''}`}
                    onClick={() => handlerSetTab(tabNames.urOsobam)}>
                    <button className={style.btn}>{t('tab.urOsobam')}</button>
                </li>
            </ul>
            <div className={style.body}>
                {
                    tab.flat && <TabFlat/>
                }
                {
                    tab.house && <TabHouse/>
                }
                {
                    tab.garage && <TabGarage/>
                }
                {
                    tab.urOsobam && <TabUrOsobam/>
                }
            </div>
        </div>
    );
};

export default Tabs;