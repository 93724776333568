import React from 'react';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Nav = () => {
    const {t, i18n} = useTranslation();

    const navLinks = [
        {
            href: "/",
            name: t('navMain'),
        },
        {
            href: "/services",
            name: t('navServices'),
        },
        {
            href: "/documents",
            name: t('navDocuments'),
        },
        {
            href: "/aboutCompany",
            name: t('navAboutCompany'),
        },
        {
            href: "/contacts",
            name: t('navContacts'),
        }
    ]


    return (
        <nav className="nav">
            <ul className="nav_list">
                {
                    navLinks.map(item => (
                        <li className="nav_list--item" key={item.name}>
                            <NavLink className="nav_list--link" to={item.href}>{item.name}</NavLink>
                        </li>
                    ))
                }
            </ul>
        </nav>
    );
};

export default Nav;