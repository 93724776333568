import Routing from "./routing";
import Modal from "./pages/Modal";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export const GlobalContext = React.createContext({});

function App() {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const initContext = {
        modal: {
            value: isOpenModal,
            setValue: setIsOpenModal
        }
    };

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    useEffect(()=>{
        window.addEventListener("message", (event) => {
                console.log(event.data);
        });
    }, [])

    return (
        <GlobalContext.Provider value={initContext}>
            <Routing/>
            <Modal isOpen={isOpenModal} setIsOpen={setIsOpenModal}/>
        </GlobalContext.Provider>
    );
}

export default App;
