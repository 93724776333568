import React from 'react';
import ServiceCard from "../Services/Card/ServiceCard";
import {useTranslation} from "react-i18next";

const ServicesList = () => {
    const {t, i18n} = useTranslation();

    return (
        <div className="container">
            <h2 className="h2">{t("navServices")}</h2>
            <ServiceCard/>
        </div>
    );
};

export default ServicesList;