import emailjs from 'emailjs-com';

export const sendEmail = () => {
  const templateParams = {
    to_email: process.env.REACT_APP_EMAIL_TO,
    message: process.env.REACT_APP_EMAIL_MESSAGE,
  };

  const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
  const templateID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
  const userID = process.env.REACT_APP_EMAIL_USER_ID;

  emailjs
    .send(serviceID, templateID, templateParams, userID)
    .then((response) => {
      console.log('Письмо успешно отправлено!', response);
    })
    .catch((error) => {
      console.error('Ошибка при отправке письма:', error);
    });
};
