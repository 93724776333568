import React from "react";
import { useTranslation } from "react-i18next";
import LocationIcon from "../../assets/img/icons/location.svg";
import PhoneIcon from "../../assets/img/icons/phone.svg";
import EmailIcon from "../../assets/img/icons/email.svg";

const ContactsInfo = () => {
    const { t, i18n } = useTranslation();

    const dataContacts = {
        phones: [
            {
                title: t("contacts.titlePhone"),
                text: t("contacts.phone1"),
                link: `tel: ${t("contacts.phone1")}`,
            }
        ],
        emails: [
            {
                title: t("contacts.titleEmail"),
                text: t("contacts.email1"),
                link: `mailto: ${t("contacts.email1")}`,
            }
        ],
        locations: [
            {
                title: t("contacts.location"),
                text: t("contacts.location"),
                link: "https://goo.gl/maps/bNk4WoLKznFHw9297",
            }
        ]
    };

    const getNode = (name) => (
        dataContacts[name].map((item) => (
            <li className="contacts_info--linkWrapper" key={Math.random()}>
                <a
                    className="contacts_info--link"
                    href={item.link}
                    target="_blank"
                    title={item.title}
                >
                    <strong>{item.text}</strong>
                </a>
            </li>
        ))
    );

    return (
        <ul className="contacts_info contacts_listIcon">
            <li className="contacts_info--item">
                <div className="contacts_info--iconWrapper">
                    <img
                        className="contacts_info--icon"
                        src={PhoneIcon}
                        alt="icon: Location"
                    />
                </div>
                <ul className="contacts_info--list">
                    {getNode('phones')}
                </ul>
            </li>
            <li className="contacts_info--item">
                <div className="contacts_info--iconWrapper">
                    <img
                        className="contacts_info--icon"
                        src={EmailIcon}
                        alt="icon: Location"
                    />
                </div>
                <ul className="contacts_info--list">
                    {getNode('emails')}
                </ul>
            </li>
            <li className="contacts_info--item">
                <div className="contacts_info--iconWrapper">
                    <img
                        className="contacts_info--icon"
                        src={LocationIcon}
                        alt="icon: Location"
                    />
                </div>
                <ul className="contacts_info--list">
                    {getNode('locations')}
                </ul>
            </li>
        </ul>
    );
};

export default ContactsInfo;
