import React, {useContext} from 'react';
import style from "./Benefits.module.sass";
import {useTranslation} from "react-i18next";
import ApplyBtn from "../../../UI/ApplyBtn";
import {GlobalContext} from "../../../App";
import Expert from "../../../assets/img/icons/expert.svg";
import Speed from "../../../assets/img/icons/speed.svg";
import Like from "../../../assets/img/icons/like.svg";
import Map from "../../../assets/img/icons/map.svg";

const Benefits = ({checkBtn=true}) => {
    const {t, i18n} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    const data = [
        {
            id: "14",
            icon: Speed,
            alt: 'icon: speed',
            title: t('benefit.title1'),
            text: t('benefit.text1')
        },
        {
            id: "15",
            icon: Expert,
            alt: 'icon: expert',
            title: t('benefit.title2'),
            text: t('benefit.text2')
        },
        {
            id: "16",
            icon: Like,
            alt: 'icon: like',
            title: t('benefit.title3'),
            text: t('benefit.text3')
        },
        {
            id: "17",
            icon: Map,
            alt: 'icon: map',
            title: t('benefit.title4'),
            text: t('benefit.text4')
        },
    ]

    return (
        <div className={`mb-140 ${style.wrapper}`} id="whyWe">
            <h3 className={`h2 ${style.header}`}>{t('benefit.header')}</h3>
            <ul className={style.list}>
                {
                    data.map(item => (
                        <li className={style.item} key={item.id}>
                            <div className={style.imgWrapper}>
                                <img className={style.icon} src={item.icon} alt={item.alt}/>
                            </div>
                            <p className={style.text}>{item.text}</p>
                        </li>
                    ))
                }
            </ul>
            {
                checkBtn ?
                    <ApplyBtn btnName={t('advantage.btn')} onClick={() => setValue(true)}/>
                    : ""
            }
        </div>
    );
};

export default Benefits;