import React from 'react';
import {useTranslation} from "react-i18next";
import ContactsInfo from "../components/Contacts/ContactsInfo";
import Networks from "../components/Contacts/networks";
import Map from "../components/Contacts/Map";
import Banner from '../components/common/Banner/Banner.js';
import ContactsImg from '../assets/img/bg.jpg';
import Promo1 from "../components/Commercial/Promo1";
import Location from "../components/common/Location/Location";

const Contacts = () => {
    const {t, i18n} = useTranslation();

    return (
        <section className="wrapper">
            <Banner header={t('contacts.header')} bgImage={ContactsImg}/>
            <div className="container">
                <ContactsInfo/>
                <Networks/>
                <Promo1/>
                <Location/>
                <Map/>
            </div>
        </section>
    );
};

export default Contacts;