import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import style from "./Location.module.sass";
import ApplyBtn from "../../../UI/ApplyBtn";
import {GlobalContext} from "../../../App";
import Map from "../../../assets/img/icons/mapUa.svg";

const Location = () => {
    const {t} = useTranslation();
    const {modal: {setValue}} = useContext(GlobalContext);

    return (
        <div className="mb-140">
            <h2 className="h2">{t('location.header')}</h2>
            <div className={style.content}>
                <div className={style.map}>
                    <img className={style.img} src={Map} alt={"img: Map"}/>
                    <ul className={style.list}>
                        <li>{t("location.list.kyiv")}</li>
                        <li>{t("location.list.poltava")}</li>
                        <li>{t("location.list.khr")}</li>
                        <li>{t("location.list.dnipro")}</li>
                        <li>{t("location.list.lviv")}</li>
                        <li>{t("location.list.odessa")}</li>
                        <li>{t("location.list.khmeln")}</li>
                    </ul>
                </div>
                <div className={style.textBlock}>
                    <h6 className="h5">{t('location.title')}</h6>
                    <p className="p">{t('location.text')}</p>
                    <ApplyBtn classBtn={"m-30"} btnName={t('apply')} onClick={() => setValue(true)}/>
                </div>
            </div>
        </div>
    );
};

export default Location;